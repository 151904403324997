/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExtendedTooltip, FlexContainer, SVGIcon } from "@nordcloud/gnui";
import {
  BatchActionFragment,
  EventActionFragment,
  EventActionScope,
  EventStatus,
  IncomingEventsListBatchActionFragment,
} from "~/generated/graphql";
import { isEmpty } from "~/tools";
import { Status, getActionStatus, getEventStatus } from "../helpers";
import { EntityType } from "./types";

type Props = {
  status?: string;
  actionLength?: number;
  type: EntityType;
  size?: "md" | "lg";
  actions?: Array<
    | EventActionFragment
    | BatchActionFragment
    | IncomingEventsListBatchActionFragment
  >;
};

export function TooltipIconStatus(props: Props) {
  const statusInfo = getStatusInfo(props);

  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <ExtendedTooltip
        status={statusInfo.tooltipColor}
        caption={statusInfo.description}
        hideTimeout={0}
        position="start"
      >
        <FlexContainer justifyContent="center" alignItems="center">
          <SVGIcon
            size={props.size}
            name={statusInfo.icon}
            color={statusInfo.color()}
          />
        </FlexContainer>
      </ExtendedTooltip>
    </FlexContainer>
  );
}

function getStatusInfo({
  status,
  actionLength,
  type,
  actions = [],
}: Omit<Props, "size">): Status {
  if (type === EntityType.ACTION) {
    return getActionStatus(status);
  }

  const eventStatus = getEventStatus(status, actionLength);

  if (
    type === EntityType.FUTURE_EVENT ||
    type === EntityType.FUTURE_ACTION_BATCH
  ) {
    return eventStatus;
  }

  if (status === EventStatus.Success) {
    const resourceGroupsEmpty = actions?.every((action) =>
      isEmpty(action.resourceGroups ?? [])
    );
    const icon = "statusSuccessDetailed";

    if (isEmpty(actions)) {
      return {
        ...eventStatus,
        icon: icon,
        description: "No actions added.",
      };
    }

    if (
      (actions?.every(
        (action) =>
          action.__typename === "EventAction" &&
          action?.scope === EventActionScope.Resource
      ) &&
        resourceGroupsEmpty) ||
      (type === EntityType.ACTION_BATCH && resourceGroupsEmpty)
    ) {
      return {
        ...eventStatus,
        icon: icon,
        description: "No resource group added.",
      };
    }
  }

  return eventStatus;
}
