/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Tab, Tabs } from "@nordcloud/gnui";
import {
  EventEntity,
  EventEntityWithOutputUri,
} from "~/views/events/components/types";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";
import { ActionDetails } from "./ActionDetails";
import { GeneralActionSettings } from "./GeneralActionSettings";

type Props = {
  eventEntity?: EventEntity;
  planId?: string;
};

export function ActionSettingsSection({ eventEntity, planId }: Props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={
            <span>
              <TabIcon caption="Action Details" icon="eventLog" />
            </span>
          }
          width="auto"
        >
          <ActionDetails
            eventEntity={eventEntity as EventEntityWithOutputUri}
          />
        </Tab>
        <Tab
          label={
            <span>
              <TabIcon caption="General Action Settings" icon="settings" />
            </span>
          }
          width="auto"
        >
          <GeneralActionSettings eventEntity={eventEntity} planId={planId} />
        </Tab>
      </Tabs>
    </>
  );
}
