/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Tab, Tabs } from "@nordcloud/gnui";
import { EventActionBatch } from "~/generated/graphql";
import { ActionBatchDetails } from "~/views/events/EventsDetails/Tabs/Details/components/ActionBox/components/ActionBatchDetails";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";

import { GeneralActionBatchSettings } from "./GeneralActionBatchSettings";

type Props = {
  eventActionBatch?: EventActionBatch;
  planId?: string;
};

export function ActionBatchSettingsSection({
  eventActionBatch,
  planId,
}: Props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={
            <span>
              <TabIcon caption="Action Batch Details" icon="eventLog" />
            </span>
          }
          width="auto"
        >
          <ActionBatchDetails eventActionBatch={eventActionBatch} />
        </Tab>
        <Tab
          label={
            <span>
              <TabIcon
                caption="General Action Batch Settings"
                icon="settings"
              />
            </span>
          }
          width="auto"
        >
          <GeneralActionBatchSettings
            eventActionBatch={eventActionBatch}
            planId={planId}
          />
        </Tab>
      </Tabs>
    </>
  );
}
