/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { theme } from "@nordcloud/gnui";
import { EventStatus, IncomingEventStatus } from "~/generated/graphql";
import { ExpandIcon } from "~/components";
import { MediumIconColumn, SmallIconColumn } from "~/components/Tables/styles";
import { TooltipIconStatus } from "./TooltipIconStatus";
import { EntityType, IncomingEventPlanEntity } from "./types";
import { getTotalResourceCount } from "./utils";

const columnHelper = createColumnHelper<IncomingEventPlanEntity>();

type Props = {
  status: EventStatus | IncomingEventStatus;
};
export function planActionsColumns({ status }: Props) {
  return [
    columnHelper.accessor(() => null, {
      header: "",
      id: "dummy",
      meta: SmallIconColumn,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <ExpandIcon
            name="chevronDown"
            title="chevronDown"
            isExpanded={row.getIsExpanded()}
            onClick={row.getToggleExpandedHandler()}
          />
        ) : null;
      },
    }),
    columnHelper.accessor(() => null, {
      header: "",
      id: "state",
      cell: ({ row }) => {
        return (
          <div
            style={{
              paddingLeft: `${row.depth * 4}rem`,
            }}
          >
            {row.getCanExpand() ? (
              <TooltipIconStatus
                status={status}
                type={EntityType.FUTURE_ACTION_BATCH}
                actions={row.original.batchActions ?? []}
              />
            ) : (
              <TooltipIconStatus status={status} type={EntityType.ACTION} />
            )}
          </div>
        );
      },
      meta: MediumIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "Action Name",
      cell: ({ row }) => {
        return (
          <div
            style={{
              paddingLeft: `${row.depth * 2.5}rem`,
            }}
          >
            {row.original.name}
          </div>
        );
      },
      meta: {
        css: {
          width: "100%",
          minWidth: "20rem",
          padding: theme.spacing.spacing03,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Number of resources",
      cell: ({ row }) => {
        return <div>{getTotalResourceCount(row.original.resourceGroups)}</div>;
      },
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Successful",
      cell: () => "-",
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Unsuccessful",
      cell: () => "-",
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing03,
        },
      },
    }),
  ] as never;
}
