/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box } from "@nordcloud/gnui";
import { EventActionBatch } from "~/generated/graphql";

import { ActionBatchSettingsSection } from "./components/ActionBatchSettingsSection";

type Props = {
  eventActionBatch?: EventActionBatch;
  planId?: string;
};

export function EventActionBatchBox({ eventActionBatch, planId }: Props) {
  return (
    <Box innerSpacing="spacing00" css={{ height: "100%" }}>
      <ActionBatchSettingsSection
        eventActionBatch={eventActionBatch}
        {...{ planId }}
      />
    </Box>
  );
}
