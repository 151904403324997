/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import {
  EventsSortFields,
  EventStatus,
  IncomingEventStatus,
  IncomingEventsSortFields,
  SortOrder,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { capitalize } from "~/tools";
import { EventsFilterFields, EventType, Period } from "./types";

export type SortFields = EventsSortFields | IncomingEventsSortFields;

export type QueryGetEvents = {
  viewPeriod: Period;
  sortField: SortFields;
  order: SortOrder;
  startDateUTC?: string;
  endDateUTC?: string;
  eventsType: EventType;
  eventStatuses: string[];
  excludeEventStatuses: boolean;
};

const sortOptions = [
  {
    value: EventsSortFields.StartTime,
    label: "Event Start Time",
  },
  {
    value: EventsSortFields.EstimatedEndTime,
    label: "Event End Time",
  },
  {
    value: EventsSortFields.Name,
    label: "Event Name",
  },
  {
    value: EventsSortFields.Status,
    label: "Event Status",
  },
];

const periodOptions = [
  {
    label: capitalize(Period.week),
    value: Period.week,
  },
  {
    label: capitalize(Period.month),
    value: Period.month,
  },
  {
    label: capitalize(Period.year),
    value: Period.year,
  },
];

const eventTypeOptions = [
  {
    label: capitalize(EventType.pastCurrent).replace("_", "/"),
    value: EventType.pastCurrent,
  },
  {
    label: capitalize(EventType.future),
    value: EventType.future,
  },
];

const todayEnd = dayjs().endOf("day").format(dateFormat.fullDate);
const weekEnd = dayjs().endOf("week").format(dateFormat.fullDate);
const monthEnd = dayjs().endOf("month").format(dateFormat.fullDate);
const yearEnd = dayjs().endOf("year").format(dateFormat.fullDate);

const weekBegin = dayjs()
  .startOf("day")
  .subtract(1, "week")
  .format(dateFormat.fullDate);

const monthBegin = dayjs()
  .startOf("day")
  .subtract(1, "month")
  .format(dateFormat.fullDate);

const yearBegin = dayjs()
  .startOf("day")
  .subtract(1, "year")
  .format(dateFormat.fullDate);

export {
  sortOptions,
  periodOptions,
  eventTypeOptions,
  todayEnd,
  weekBegin,
  weekEnd,
  monthBegin,
  monthEnd,
  yearBegin,
  yearEnd,
};

export const filterKeys = [
  EventsFilterFields.startDate,
  EventsFilterFields.endDate,
];

export const eventStatusCheckboxes = [
  {
    value: EventStatus.InProgress,
    label: "In Progress",
  },
  {
    value: EventStatus.Success,
    label: "Successful",
  },
  {
    value: EventStatus.PartialSuccess,
    label: "Partially Successful",
  },
  {
    value: EventStatus.WaitingForApproval,
    label: "Unapproved",
  },
  {
    value: EventStatus.Failed,
    label: "Failed",
  },
];

export const incomingEventStatusCheckboxes = [
  {
    value: IncomingEventStatus.Revoked,
    label: "Revoked",
  },
  {
    value: IncomingEventStatus.Upcoming,
    label: "Upcoming",
  },
];
