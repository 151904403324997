/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { DraggableProvided } from "react-beautiful-dnd";
import { When } from "react-if";
import { Button, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";
import {
  ActionBatchWrap,
  ActionBatchHeader,
  ActionBatchLabel,
} from "~/views/plans/styled";

type Props = {
  name?: string | JSX.Element;
  provided?: DraggableProvided;
  children: React.ReactNode;
  onDelete?: () => void;
  onAddAction?: () => void;
  onClick?: () => void;
  active?: boolean;
  editName?: string | JSX.Element;
};

export function ActionBatch({
  name,
  provided,
  children,
  onDelete,
  onAddAction,
  onClick,
  active,
  editName,
}: Props) {
  return (
    <ActionBatchWrap
      active={active}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      onMouseDown={onClick}
    >
      <ActionBatchLabel>Action Batch</ActionBatchLabel>
      <ActionBatchHeader alignItems="center" gap={theme.spacing.spacing02}>
        <When condition={isNotNil(provided)}>
          <div
            {...provided?.dragHandleProps}
            css={{ marginRight: `${theme.spacing.spacing03}` }}
          >
            <SVGIcon name="dragAndDrop" size="md" />
          </div>
        </When>
        <Text
          weight="medium"
          tag="span"
          css={{
            marginRight: "auto",
          }}
        >
          {editName ?? name}
        </Text>
        <When condition={isNotNil(onAddAction)}>
          <Button
            icon="plus"
            size="md"
            severity="low"
            onClick={onAddAction}
            type="button"
          />

          <div css={{ paddingRight: `${theme.spacing.spacing03}` }}>
            <Button onClick={onDelete} icon="trash" size="md" severity="low" />
          </div>
        </When>
      </ActionBatchHeader>
      {children}
    </ActionBatchWrap>
  );
}
