/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEventActionStatusChangedSubscription } from "~/generated/graphql";
import { TooltipIconStatus } from "./TooltipIconStatus";
import { EntityType } from "./types";

type Props = {
  eventActionId: string;
  eventActionStatus?: string;
  onEventActionStatusChanged: () => void;
};

export function EventActionStatus({
  eventActionId,
  eventActionStatus,
  onEventActionStatusChanged,
}: Props) {
  const { data } = useEventActionStatusChangedSubscription({
    variables: {
      input: {
        id: eventActionId,
      },
    },
    onData: onEventActionStatusChanged,
  });
  return (
    <TooltipIconStatus
      status={data?.eventActionStatusChanged ?? eventActionStatus}
      type={EntityType.ACTION}
    />
  );
}
