/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Box, Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { Mode } from "~/components";
import { FilterWrapper } from "~/components/SidebarWithMultiSelect/FilterWrapper";
import { SelectableListWithSearch } from "~/components/SidebarWithMultiSelect/SelectableListWithSearch";
import { useDisclosure, useQueryState } from "~/hooks";
import { isNotEmpty, isNotNil } from "~/tools";
import {
  eventStatusCheckboxes,
  incomingEventStatusCheckboxes,
  QueryGetEvents,
} from "~/views/events/components/constants";
import { EventType } from "./types";

type SidebarFooterProps = {
  onApply: () => void;
  onClear: () => void;
};

function SidebarFooter({ onApply, onClear }: SidebarFooterProps) {
  return (
    <Col>
      <Row>
        <Box spacing="spacing02" css={{ marginBottom: 0 }}>
          <FlexContainer justifyContent="start" alignItems="center">
            <Button
              mr={theme.spacing.spacing04}
              severity="low"
              onClick={onApply}
            >
              Apply
            </Button>
            <Button
              mr={theme.spacing.spacing04}
              severity="low"
              onClick={onClear}
            >
              Clear
            </Button>
          </FlexContainer>
        </Box>
      </Row>
    </Col>
  );
}

export function EventFiltersSidebar() {
  const { isOpen, open, close } = useDisclosure();
  const { queryState, updateQueryState } = useQueryState<QueryGetEvents>();
  const [eventStatuses, setEventStatuses] = useState(queryState.eventStatuses);
  const [isEventStatusesExcluded, setIsEventStatusesExcluded] = useState(
    queryState.excludeEventStatuses
  );

  // synchronize states
  useEffect(() => {
    setEventStatuses(queryState.eventStatuses);
  }, [queryState.eventStatuses, isOpen, setEventStatuses]);

  const clearEventStatusFilters = () => {
    setEventStatuses([]);
    setIsEventStatusesExcluded(false);
  };

  const clearAllFilters = () => {
    setEventStatuses([]);
    setIsEventStatusesExcluded(false);
    updateQueryState({
      ...queryState,
      eventStatuses: [],
      excludeEventStatuses: false,
    });
    close();
  };

  const applyFilters = () => {
    updateQueryState({
      ...queryState,
      eventStatuses: eventStatuses,
      excludeEventStatuses: isEventStatusesExcluded,
    });
    close();
  };

  return (
    <>
      <Button
        mr={theme.spacing.spacing04}
        severity="low"
        icon="filter"
        onClick={open}
      >
        Filters
      </Button>
      <Sidebar
        title="Filter Results"
        isOpen={isOpen}
        onClick={close}
        footer={
          <SidebarFooter onApply={applyFilters} onClear={clearAllFilters} />
        }
      >
        <FilterWrapper
          name="Event Status"
          isSelected={isNotNil(eventStatuses) && isNotEmpty(eventStatuses)}
          selectedOptions={eventStatuses ?? []}
          onClear={clearEventStatusFilters}
        >
          <SelectableListWithSearch
            allowModeChange
            selectedOptions={eventStatuses ?? []}
            selectableItems={
              queryState.eventsType === EventType.pastCurrent
                ? eventStatusCheckboxes
                : incomingEventStatusCheckboxes
            }
            isExcludeSelected={isEventStatusesExcluded}
            onMultiSelect={(statuses) => setEventStatuses(statuses)}
            onModeChange={(mode) =>
              setIsEventStatusesExcluded(mode === Mode.EXCLUDES)
            }
          />
        </FilterWrapper>

        {/* EVENT ACTIONS To be implemented  */}
        {/* <Box spacing="spacing02" boxStyle="lightGrey">
          <Row>
            <FlexContainer justifyContent="center" alignItems="center">
              <ExpandIcon
                name="chevronDown"
                title="chevronDown"
                isExpanded={isActionExpanded}
                onClick={toggleIsActionExpanded}
              />
              <Text size="md" m={0} color="text04">
                Event Actions
              </Text>
            </FlexContainer>
          </Row>
          <When condition={isActionExpanded}>
            <p>Action filters go here</p>
          </When>
        </Box> */}
      </Sidebar>
    </>
  );
}
