/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import { Box, FlexContainer, Text, theme, Switch } from "@nordcloud/gnui";
import {
  BatchAction,
  BatchedEventAction,
  EventAction,
  EventActionAttempt,
  EventActionBatch,
  EventActionScope,
  useEventActionStatusChangedSubscription,
  useGetEventActionsResourceStatesQuery,
} from "~/generated/graphql";
import { NoData } from "~/components";
import { useToggle } from "~/hooks";
import { isNonEmpty, sortItem } from "~/tools";
import { EventToggleItemBox } from "~/views/events/components/EventToggleItemBox/EventToggleItemBox";
import { EventEntity } from "~/views/events/components/types";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { EventActionBatchBox } from "~/views/events/EventsDetails/Tabs/Details/components/ActionBox/EventActionBatchBox";
import { ReportView } from "~/views/events/EventsDetails/Tabs/Details/components/ReportView/ReportView";
import {
  getActionStatus,
  isResourceScope,
  shouldAwaitResourceStates,
} from "~/views/events/helpers";
import { useGetEvent } from "~/views/events/hooks";
import { WrapMax } from "~/views/plans/styled";
import { EventActionBox } from "./components/ActionBox/EventActionBox";
import { EventActionBatchItem } from "./components/EventActionBatchItem";
import { EventDetailsTabCtxProvider } from "./EventDetailsTabProvider";

export function EventDetailsTab() {
  const { event: eventCtx } = useEvent();

  const actions = eventCtx.actions;
  const actionBatches = eventCtx.actionBatches;

  const hasActions =
    isNonEmpty(actions) ||
    actionBatches.some((batch: EventActionBatch) => isNonEmpty(batch.actions));

  if (hasActions) {
    return EventActionDetailsTab();
  }

  return <NoData hasIcon message="There are no Actions" />;
}

function EventActionDetailsTab() {
  const { event: eventCtx } = useEvent();
  const actions = eventCtx.actions;
  const batches = eventCtx.actionBatches;

  const [attempt, setAttempt] = useState<EventActionAttempt>();
  const [isReportView, toggleReportView] = useToggle(false);

  const { event, refetch: refetchEvent } = useGetEvent({
    eventId: eventCtx.id,
  });

  const { startPolling, stopPolling } = useGetEventActionsResourceStatesQuery({
    variables: {
      id: eventCtx.id,
    },
    fetchPolicy: "network-only",
  });

  const [eventAction, setEventAction] = useState<EventEntity>(actions[0]);
  const [eventActionBatch, setEventActionBatch] = useState<EventActionBatch>();

  useEventActionStatusChangedSubscription({
    variables: {
      input: {
        // globalState is recalculated at product api - needs to be refetched when latest attempt status changes
        id: eventAction.attempts
          ? eventAction.attempts[eventAction.attempts.length - 1].id
          : "",
      },
    },
    onData: () => {
      refetchEvent();
    },
  });

  const handleSelectAttempt = (value: EventActionAttempt) => setAttempt(value);

  const updatedResourceStates = useMemo(() => {
    const batchActions = batches?.reduce(
      (acc: EventActionBatch["actions"], batch: EventActionBatch) => {
        return [...acc, ...(batch?.actions ?? [])];
      },
      []
    );

    const allActions = [...(event?.actions ?? []), ...batchActions];

    return (
      allActions?.filter((action) => {
        return eventAction.id === action.id;
      })[0]?.resourceStates ?? []
    );
  }, [event?.actions, eventAction.id, batches]);

  const awaitResourceStates = useCallback(() => {
    return shouldAwaitResourceStates(eventAction, updatedResourceStates);
  }, [eventAction, updatedResourceStates]);

  const resourceStates = attempt
    ? attempt?.resourceStates
    : updatedResourceStates;

  useEffect(() => {
    if (awaitResourceStates()) {
      startPolling(2000);
    } else {
      stopPolling();
    }
  }, [awaitResourceStates, startPolling, stopPolling]);

  const sortedItems = useMemo(() => {
    const batchItems = batches.map((batch: EventActionBatch) => ({
      ...batch,
      isBatch: true,
      actions: batch.actions.map((action: BatchedEventAction) => ({
        ...action,
        scope: EventActionScope.Resource,
      })),
    }));
    const actionItems = actions.map((action: EventAction) => ({
      ...action,
      isBatch: false,
    }));
    return sortItem(
      [...batchItems, ...actionItems],
      (a, b) => a.order - b.order
    );
  }, [batches, actions]);

  const EventActionItem = useMemo(() => {
    return ({ item }: { item: EventEntity }) => {
      const attempts =
        item?.attempts && item.attempts?.length > 1 ? item.attempts : [];
      const statusDetails = getActionStatus(item.globalState?.status);

      return (
        <EventToggleItemBox
          item={{
            id: item.id,
            color: item.action?.color ?? theme.color.support.grey,
            name: item.name,
            icon: statusDetails.icon,
            iconColor: statusDetails.color(),
            tooltip: statusDetails.description,
            tooltipColor: statusDetails.tooltipColor,
            onMouseDown: () => {
              setEventAction(item);
              setAttempt(undefined);
              setEventActionBatch(undefined);
            },
            onSelect: handleSelectAttempt,
          }}
          attempts={attempts}
          selectedAttempt={attempt}
          active={item.id === eventAction.id && !eventActionBatch}
        />
      );
    };
  }, [eventAction.id, attempt, eventActionBatch]);

  return (
    <Box innerSpacing="spacing00">
      <EventDetailsTabCtxProvider value={{ eventAction, attempt, setAttempt }}>
        <Row>
          <Col xs={8}>
            <FlexContainer
              direction="row"
              justifyContent="space-between"
              padding={`${theme.spacing.spacing04} 0`}
            >
              <div>
                <Text width="100%" align="left" size="md" weight="medium">
                  Actions
                </Text>
              </div>
              <div>
                <When condition={isResourceScope(eventAction)}>
                  <Switch
                    name="label-left"
                    position="left"
                    labelText="Report View"
                    onChange={toggleReportView}
                  />
                </When>
              </div>
            </FlexContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={8} md={3} lg={3}>
            <FlexContainer direction="column" justifyContent="flex-start">
              {sortedItems.map((item) => (
                <If condition={item.isBatch} key={item.id}>
                  <Then>
                    <WrapMax css={{ paddingTop: `${theme.spacing.spacing03}` }}>
                      <EventActionBatchItem
                        actionBatch={item}
                        active={eventActionBatch?.id === item.id}
                        onClick={() => {
                          setEventActionBatch(item);
                        }}
                      >
                        {item.actions?.map((batchAction: BatchAction) => (
                          <EventActionItem
                            key={batchAction.id}
                            item={batchAction}
                          />
                        ))}
                      </EventActionBatchItem>
                    </WrapMax>
                  </Then>

                  <Else>
                    <WrapMax css={{ marginLeft: `${theme.spacing.spacing04}` }}>
                      <EventActionItem key={item.id} item={item} />
                    </WrapMax>
                  </Else>
                </If>
              ))}
            </FlexContainer>
          </Col>
          <Col xs={4} sm={8} md={5} lg={9}>
            <Box boxStyle="grey" innerSpacing="spacing04">
              <FlexContainer alignItems="flex-start">
                <If condition={isReportView}>
                  <Then>
                    <ReportView
                      eventId={eventCtx.id}
                      eventActionId={eventAction.id}
                      attempt={attempt}
                    />
                  </Then>
                  <Else>
                    <If condition={!!eventActionBatch}>
                      <Then>
                        <EventActionBatchBox
                          eventActionBatch={eventActionBatch}
                          planId={eventCtx?.plan?.id}
                        />
                      </Then>
                      <Else>
                        <EventActionBox
                          eventEntity={eventAction}
                          resourceStates={resourceStates}
                          planId={eventCtx?.plan?.id}
                          attempt={attempt}
                        />
                      </Else>
                    </If>
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </EventDetailsTabCtxProvider>
    </Box>
  );
}
