/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BatchActionFragment } from "~/generated/graphql";
import { TooltipIconStatus } from "./TooltipIconStatus";
import { EntityType } from "./types";

type Props = {
  eventActionBatchId: string;
  eventActionBatchStatus?: string;
  batchActions?: Array<BatchActionFragment>;
  // onEventActionBatchStatusChanged: () => void;
};

export function EventActionBatchStatus({
  eventActionBatchStatus,
  batchActions,
}: Props) {
  // Add action batch status subscription here
  // https://nordcloud.atlassian.net/browse/AP-4783
  return (
    <TooltipIconStatus
      status={eventActionBatchStatus}
      type={EntityType.ACTION_BATCH}
      actions={batchActions}
    />
  );
}
