/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import { Spacer, Text, theme } from "@nordcloud/gnui";
import { EventActionBatch } from "~/generated/graphql";
import { ROUTES } from "~/routing/routes";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { convertMinutesToTime } from "~/views/events/helpers";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";

export function GeneralActionBatchSettings({
  eventActionBatch,
  planId,
}: {
  eventActionBatch?: EventActionBatch;
  planId?: string;
}) {
  const isDelay = eventActionBatch?.name === SystemActionNames.DELAY;

  return (
    <>
      <Text weight="medium">General Action Batch Settings</Text>
      <DisplayExecutionPolicy policy={eventActionBatch?.executionPolicy} />
      <Text size="sm" tag="div">
        Resource Operation
      </Text>
      <Text>Simultaneous</Text>
      <If condition={isDelay}>
        <Then>
          <Text size="sm" tag="div">
            Action Delay
          </Text>
          <Text mb={theme.spacing.spacing01}>
            {convertMinutesToTime(Number(eventActionBatch?.windowDuration))}
          </Text>
          <Spacer height={theme.spacing.spacing03} />
        </Then>
        <Else>
          <DisplayWindowDuration
            windowDuration={eventActionBatch?.windowDuration}
          />
        </Else>
      </If>
      <Text size="sm" tag="div">
        Ignore Window
      </Text>
      <Text mb={theme.spacing.spacing01}>
        {convertSkipWindow(eventActionBatch?.skipWindow)}
      </Text>

      <Spacer height={theme.spacing.spacing02} />
      <Link to={`${ROUTES.plans.index}/${planId}`}>
        Go to plan to edit settings
      </Link>
    </>
  );
}
