/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { Text } from "@nordcloud/gnui";
import { EventActionBatch, ExecutionStatus } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isNotStatusInProgressOrUpcoming } from "~/views/events/EventsDetails/Tabs/Details/components/utils";

export function ActionBatchDetails({
  eventActionBatch,
}: {
  eventActionBatch?: EventActionBatch;
}) {
  const startTime = dayjs(eventActionBatch?.startTime).format(
    dateFormat.dayMonthYearHourMinute
  );

  const endTime = dayjs(eventActionBatch?.startTime)
    .add(eventActionBatch?.windowDuration || 0, "minutes")
    .format(dateFormat.dayMonthYearHourMinute);

  return (
    <div css={{ minHeight: "19.3rem" }}>
      <Text weight="medium">Action Batch Details</Text>
      <Text size="sm" tag="div">
        Name
      </Text>
      <Text>{eventActionBatch?.name}</Text>
      <When
        condition={
          eventActionBatch?.startTime &&
          eventActionBatch?.status !== ExecutionStatus.Upcoming
        }
      >
        <Text size="sm" tag="div">
          Start Time (local)
        </Text>
        <Text>{startTime}</Text>
      </When>
      <When
        condition={
          eventActionBatch?.startTime &&
          eventActionBatch?.windowDuration &&
          isNotStatusInProgressOrUpcoming(eventActionBatch?.status)
        }
      >
        <Text size="sm" tag="div">
          End Time (local)
        </Text>
        <Text>{endTime}</Text>
      </When>
    </div>
  );
}
