/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { When } from "react-if";
import { Box, FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import { EventActionAttempt, EventActionScope } from "~/generated/graphql";
import { getLastItem } from "~/tools";
import { EventEntityWithOutputUri } from "~/views/events/components/types";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ActionLog } from "./ActionLog";
import { Rerun } from "./Rerun";

type Props = {
  eventEntity: EventEntityWithOutputUri;
  attempt?: EventActionAttempt;
};

export function EventResourceHeader({ eventEntity, attempt }: Props) {
  const { event } = useEvent();
  const { id: eventId } = event;

  const lastEventActionAttemptId =
    eventEntity?.attempts && getLastItem(eventEntity.attempts).id;

  const outputUri =
    attempt?.state?.outputUri ?? eventEntity?.globalState?.outputUri;
  const modalLabel = attempt?.attempt
    ? `Attempt ${attempt?.attempt.toString()}`
    : eventEntity?.action?.name;

  return (
    <>
      <Box>
        <FlexContainer justifyContent="flex-end">
          <When condition={eventEntity?.scope === EventActionScope.Plan}>
            <ActionLog
              outputUri={outputUri ?? ""}
              modalLabel={modalLabel ?? ""}
            />
          </When>
          <When condition={lastEventActionAttemptId}>
            <Rerun {...{ eventId }} eventActionId={lastEventActionAttemptId} />
          </When>
        </FlexContainer>
      </Box>
      <Spacer height={theme.spacing.spacing04} />
    </>
  );
}
