/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { Text } from "@nordcloud/gnui";
import { ExecutionStatus } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { EventEntityWithOutputUri } from "~/views/events/components/types";
import { LogsOutputButton } from "~/views/events/EventsDetails/components/LogOutputButton";
import { isNotStatusInProgressOrUpcoming } from "~/views/events/EventsDetails/Tabs/Details/components/utils";

export function ActionDetails({
  eventEntity,
}: {
  eventEntity?: EventEntityWithOutputUri;
}) {
  const startTime = dayjs(eventEntity?.globalState?.startTime).format(
    dateFormat.dayMonthYearHourMinute
  );

  const endTime = dayjs(eventEntity?.globalState?.endTime).format(
    dateFormat.dayMonthYearHourMinute
  );

  return (
    <div css={{ minHeight: "19.3rem" }}>
      <Text weight="medium">Action Details</Text>
      <Text size="sm" tag="div">
        Name
      </Text>
      <Text>{eventEntity?.name}</Text>
      <When
        condition={
          eventEntity?.globalState?.status !== ExecutionStatus.Upcoming
        }
      >
        <Text size="sm" tag="div">
          Start Time (local)
        </Text>
        <Text>{startTime}</Text>
      </When>
      <When
        condition={isNotStatusInProgressOrUpcoming(
          eventEntity?.globalState?.status
        )}
      >
        <Text size="sm" tag="div">
          End Time (local)
        </Text>
        <Text>{endTime}</Text>
      </When>
      <When condition={eventEntity?.globalState?.outputUri}>
        <LogsOutputButton
          actionLogLabel={eventEntity?.name}
          outputUri={eventEntity?.globalState?.outputUri}
        />
      </When>

      {/* TODO AP-4540}
      
      <When condition={isNotEmpty(eventEntity?.resourceStates ?? [])}>
        <Text size="sm" tag="div">
          Resource Status Summary
        </Text>
      </When> */}
    </div>
  );
}
