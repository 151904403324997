/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MutableRefObject } from "react";
import { When } from "react-if";
import { Checkbox, FlexContainer, Text, Loader, theme } from "@nordcloud/gnui";
import { noop } from "~/tools";
import { Separator, SidebarItemWrapper } from "../Styled";
import { FilterHeader } from "./FilterHeader";
import { useSelectableListFilter } from "./hooks";
import { SwitchWrap } from "./styles";
import { Item, Mode } from "./types";
import { getSelectedMode } from "./utils";

type Props = {
  allowModeChange?: boolean;
  isInfiniteScroll?: boolean;
  elementRef?: MutableRefObject<null>;
  isLoading?: boolean;
  selectableItems: Item[];
  selectedOptions: string[];
  onMultiSelect: (ids: string[]) => void;
  onModeChange?: (mode: Mode) => void;
  isExcludeSelected?: boolean;
};

export function SelectableListWithSearch({
  allowModeChange = false,
  isInfiniteScroll = false,
  isLoading = false,
  elementRef,
  selectedOptions = [],
  selectableItems,
  onMultiSelect,
  onModeChange = noop,
  isExcludeSelected = false,
}: Props) {
  const { selectedItems, filteredOptions, setSearch, onApply } =
    useSelectableListFilter({
      selectableItems,
      selectedOptions,
      onMultiSelect,
    });

  return (
    <>
      <FilterHeader
        showSearchBar
        allowModeChange={allowModeChange}
        defaultMode={getSelectedMode(isExcludeSelected)}
        onSetSearch={setSearch}
        onModeChange={onModeChange}
      />
      <FlexContainer
        direction="column"
        css={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <Separator />
        {filteredOptions.map(({ label, value }) => (
          <SidebarItemWrapper
            key={value}
            hideBottomBorder
            title={label ?? ""}
            onClick={() => onApply(value ?? "")}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                readOnly
                checked={isChecked(selectedItems, value ?? "")}
              />
            </SwitchWrap>
            <Text mb={0} color={theme.color.text.text01}>
              {label}
            </Text>
          </SidebarItemWrapper>
        ))}
        <When condition={isInfiniteScroll}>
          <When condition={isLoading}>
            <Loader inContent />
          </When>
          <div ref={elementRef} />
        </When>
      </FlexContainer>
    </>
  );
}

function isChecked(selectedItems: string[], value: string) {
  return selectedItems.includes(value);
}
