/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EventStatus, IncomingEventStatus } from "~/generated/graphql";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { RevokeIncomingEvent } from "../../components/RevokeIncomingEvent/RevokeIncomingEvent";
import { RefetchOptions } from "../../helpers";

type Props = {
  eventId: string;
  status?: EventStatus | IncomingEventStatus;
  size?: "large" | "small";
  refetchQueries: RefetchOptions;
};

export function EventActions({
  eventId,
  status,
  size = "small",
  refetchQueries,
}: Props) {
  const { open, close, isOpen } = useDisclosure();

  const isUpcomingEvent = () => {
    return status === IncomingEventStatus.Upcoming;
  };

  return (
    <>
      <PopoverControls
        disabled={false}
        size={size}
        items={[
          {
            label: `Revoke Event`,
            onClick: open,
            disabled: !isUpcomingEvent(),
          },
        ]}
      />
      <RevokeIncomingEvent
        eventId={eventId}
        isOpen={isOpen}
        onClose={close}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
